import { globalLoggerToken } from "@/tokens";
import { applyTransaction } from "@datorama/akita";
import {
    Observable,
    Subscription,
    bufferTime,
    combineLatest,
    filter,
    switchMap,
    tap,
} from "rxjs";
import { distinct, map, mergeMap } from "rxjs/operators";
import { inject, singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { ProductApiServiceInterface } from "../api_services/ProductApiServiceInterface";
import { ProductsApiServiceInterface } from "../api_services/ProductsApiServiceInterface";
import { ProductsLiquidApiServiceInterface } from "../api_services/ProductsLiquidApiServiceInterface";
import { ProductStatuses } from "../constants/ProductStatuses";
import { ProductsStore } from "../stores/ProductsStore";
import { ProductsWithQuantityRulesStore } from "../stores/ProductsWithQuantityRulesStore";
import {
    productApiServiceToken,
    productLiquidApiServiceToken,
    productsApiServiceToken,
    productsStoreToken,
    productsWithQuantityRulesStoreToken,
} from "../tokens";
import { ProductsServiceInterface } from "./ProductsServiceInterface";

@singleton()
export class ProductsService implements ProductsServiceInterface {
    private bufferedProductIds$: Observable<number[]>;
    private bufferedProductsWithQuantityRulesIds$: Observable<number[]>;
    private productsSubscription: Subscription | null = null;
    private productsWithQuantityRulesSubscription: Subscription | null = null;
    private isSubscribed = false;

    public constructor(
        @inject(productApiServiceToken)
        private readonly productApiService: ProductApiServiceInterface,
        @inject(productsApiServiceToken)
        private readonly productsApiService: ProductsApiServiceInterface,
        @inject(productLiquidApiServiceToken)
        private readonly productLiquidApiService: ProductsLiquidApiServiceInterface,
        @inject(productsStoreToken)
        private readonly productsStore: ProductsStore,
        @inject(productsWithQuantityRulesStoreToken)
        private readonly productsWithQuantityRulesStore: ProductsWithQuantityRulesStore,
        @inject(globalLoggerToken)
        private readonly logger: LoggerInterface
    ) {
        this.bufferedProductIds$ = this.productsStore
            ._select((state) => state.productStatuses)
            .pipe(
                mergeMap((productStatuses) => productStatuses),
                filter(({ status }) => status === ProductStatuses.IN_QUEUE),
                map((statusItem) => statusItem.productId),
                distinct(),
                bufferTime(200)
            );

        this.bufferedProductsWithQuantityRulesIds$ =
            this.productsWithQuantityRulesStore
                ._select((state) => state.productStatuses)
                .pipe(
                    mergeMap((productStatuses) => productStatuses),
                    filter(({ status }) => status === ProductStatuses.IN_QUEUE),
                    map((statusItem) => statusItem.productId),
                    distinct(),
                    bufferTime(200)
                );
    }

    public addProductForFetching(id: number) {
        this.logger.debug("ProductsService.addProductForFetching", {
            id,
        });
        this.productsStore.addProductInQueue(id);
    }

    public addProductWithQuantityRulesForFetching(id: number) {
        this.logger.debug(
            "ProductsService.addProductWithQuantityRulesForFetching",
            {
                id,
            }
        );
        this.productsWithQuantityRulesStore.addProductInQueue(id);
    }

    private createProductsSubscription() {
        this.productsSubscription = this.bufferedProductIds$
            .pipe(
                filter((ids) => ids.length >= 1),
                tap((ids) => {
                    applyTransaction(() => {
                        for (const id of ids) {
                            this.productsStore.setLoadingStatus(id);
                        }
                    });
                }),
                mergeMap((ids: number[]) => {
                    if (this.productsApiService) {
                        return this.productsApiService
                            .getProducts(ids)
                            .pipe(map((results) => ({ results, ids })));
                    }
                    return combineLatest(
                        ids.map((id) => this.productApiService.getProduct(id))
                    ).pipe(map((results) => ({ results, ids })));
                }),
                tap(({ results, ids }) => {
                    applyTransaction(() => {
                        const filteredIds = new Set(ids);
                        results.forEach((result) => {
                            filteredIds.delete(result.id);
                            this.productsStore.add(result);
                            this.productsStore.setReadyStatus(result.id);
                        });
                        filteredIds.forEach((id) => {
                            this.productsStore.deleteProductStatus(id);
                        });
                    });
                })
            )
            .subscribe();
    }

    private createProductsWithQuantityRulesSubscription() {
        this.productsWithQuantityRulesSubscription =
            this.bufferedProductsWithQuantityRulesIds$
                .pipe(
                    filter((ids) => ids.length > 0),
                    tap((ids) => {
                        applyTransaction(() => {
                            for (const id of ids) {
                                this.productsWithQuantityRulesStore.setLoadingStatus(
                                    id
                                );
                            }
                        });
                    }),
                    switchMap((ids: number[]) => {
                        return this.productLiquidApiService.getProductsWithQuantityRulesLiquid(
                            ids
                        );
                    }),
                    tap((results) => {
                        applyTransaction(() => {
                            results.forEach((result) => {
                                this.productsWithQuantityRulesStore.add(result);
                                this.productsWithQuantityRulesStore.setReadyStatus(
                                    result.id
                                );
                            });
                        });
                    })
                )
                .subscribe();
    }

    public subscribe() {
        if (this.isSubscribed) return;

        this.logger.debug("ProductsService.subscribe");
        this.isSubscribed = true;

        this.createProductsSubscription();
        this.createProductsWithQuantityRulesSubscription();
    }

    public stop() {
        this.logger.debug("ProductsService.stop");
        this.productsSubscription?.unsubscribe();
        this.productsWithQuantityRulesSubscription?.unsubscribe();
    }
}
