export enum REVIEWS_ICON_TYPES {
    default = "default",
    starBold = "starBold",
    star = "star",
    stars = "stars",
    fire = "fire",
    crown = "crown",
    coffee = "coffee",
    paint = "paint",
    diamond = "diamond",
    pets = "pets",
    leaf = "leaf",
    bag = "bag",
    cup = "cup",
    plus = "plus",
    plant = "plant",
    bell = "bell",
    loudspeaker = "loudspeaker",
    teddy = "teddy",
    tShirt = "tShirt",
    medal = "medal",
    heart = "heart",
    trophy = "trophy",
    gift = "gift",
    flash = "flash",
    capsule = "capsule",
    music = "music",
}

export const REVIEWS_ICON_TYPES_LIST = Object.values(REVIEWS_ICON_TYPES);
