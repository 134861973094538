import {
    REVIEWS_WIDGET_NAMES,
    REWARDS_WIDGET_NAMES,
} from "@/constants/widget-names";

export enum REVIEWS_FORM_STEPS_NAMES {
    RATING = "rating",
    REVIEW = "review",
    MEDIA = "photo",
    GUEST_INFO = "guest-info",
    RESULT = "result",
    ATTRIBUTES = "attributes",
}

export enum LEAVE_REVIEW_SOURCE {
    REVIEWS_PRODUCT_PAGE_WIDGET = REVIEWS_WIDGET_NAMES.REVIEWS_PRODUCT_PAGE_WIDGET,
    REVIEWS_WIDGET = REVIEWS_WIDGET_NAMES.REVIEWS_WIDGET,
    REVIEWS_DRAWER_WIDGET = REVIEWS_WIDGET_NAMES.REVIEWS_DRAWER_WIDGET,
    LEAVE_REVIEW_PAGE_WIDGET = REVIEWS_WIDGET_NAMES.LEAVE_REVIEW_PAGE_WIDGET,
    REVIEWS_PAGE_WIDGET = REVIEWS_WIDGET_NAMES.REVIEWS_PAGE_WIDGET,
    REWARDS_POPUP_WIDGET = REWARDS_WIDGET_NAMES.REWARDS_POP_UP_WIDGET,
    REWARDS_WAYS_TO_EARN_PAGE_WIDGET = REWARDS_WIDGET_NAMES.REWARDS_WAYS_TO_EARN_PAGE_WIDGET,
    LEAVE_REVIEW_BUTTON_WIDGET = REVIEWS_WIDGET_NAMES.LEAVE_REVIEW_BUTTON_WIDGET,
}

export const CAN_LEAVE_REVIEW_MESSAGE_KEY = {
    ONLY_VERIFIED_BUYER_CAN_LEAVE_REVIEW:
        "only_verified_buyer_can_leave_review",
    ONLY_LOGGED_IN_CUSTOMER_CAN_LEAVE_REVIEW:
        "only_logged_in_customer_can_leave_review",
    PRODUCT_ALREADY_REVIEWED: "product_already_reviewed",
    SHOP_ALREADY_REVIEWED: "shop_already_reviewed",
} as const;
