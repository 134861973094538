import { CustomerInfoProviderInterface } from "@lib/CustomerInfoProvider/CustomerInfoProviderInterface";
import GrowaveConfigurationEntity from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationEntity";
import { GrowaveConfigurationProviderInterface } from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationProviderInterface";
import { LocalizationInfoProviderInterface } from "@lib/LocalizationInfoProvider/LocalizationInfoProviderInterface";
import { ProductIdManagerInterface } from "@lib/ProductIdManager/ProductIdManagerInterface";
import { ProductVariantIdManagerInterface } from "@lib/ProductVariantIdManager/ProductVariantIdManagerInterface";
import { ShopBrandInfoProviderInterface } from "@lib/ShopBrandInfoProvider/ShopBrandInfoProviderInterface";
import { StorefrontApiAccessTokenProviderInterface } from "@lib/StorefrontApiAccessTokenProvider/StorefrontApiAccessTokenProviderInterface";
import type { InjectionToken } from "tsyringe";

import { ModalManager } from "@components/Modal/ModalManager";
import type GwStorefrontAppInfo from "@interfaces/GwStorefrontAppInfo";
import type { LoggerInterface } from "@interfaces/LoggerInterface";

export const localStorageToken = Symbol(
    "localStorageToken"
) as InjectionToken<Storage>;

export const globalLoggerToken = Symbol(
    "globalLoggerToken"
) as InjectionToken<LoggerInterface>;

export const gwStorefrontAppInfoToken = Symbol(
    "gwStorefrontAppInfoToken"
) as InjectionToken<GwStorefrontAppInfo>;

export const customerInfoProviderToken = Symbol(
    "customerInfoProviderToken"
) as InjectionToken<CustomerInfoProviderInterface>;

export const shopBrandInfoProviderToken = Symbol(
    "shopBrandInfoProviderToken"
) as InjectionToken<ShopBrandInfoProviderInterface>;

export const storefrontApiAccessTokenProviderToken = Symbol(
    "storefrontApiAccessTokenProviderToken"
) as InjectionToken<StorefrontApiAccessTokenProviderInterface>;

export const growaveConfigurationProviderToken = Symbol(
    "growaveConfigurationProviderToken"
) as InjectionToken<GrowaveConfigurationProviderInterface>;

export const growaveConfigurationToken = Symbol(
    "growaveConfigurationToken"
) as InjectionToken<GrowaveConfigurationEntity>;

export const modalManagerToken = Symbol(
    "modalManagerToken"
) as InjectionToken<ModalManager>;

export const localizationInfoProviderToken = Symbol(
    "localizationInfoProviderToken"
) as InjectionToken<LocalizationInfoProviderInterface>;

export const isEmbeddedModeToken = Symbol(
    "isEmbeddedModeToken"
) as InjectionToken<boolean>;

export const globalProductIdManagerToken = Symbol(
    "globalProductIdManagerToken"
) as InjectionToken<ProductIdManagerInterface>;

export const globalProductVariantsIdManagerToken = Symbol(
    "globalProductVariantsIdManagerToken"
) as InjectionToken<ProductVariantIdManagerInterface>;
