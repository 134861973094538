import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { Observable, map } from "rxjs";
import { array, create } from "superstruct";
import { inject, singleton } from "tsyringe";

import UnavailableProductEntity from "../entities/UnavailableProductEntity";
import { UnavailableProductSchema } from "../schemas/UnavailableProductSchema";
import { commonAuthApiClientToken } from "../tokens";
import {
    UnavailableProductsApiServiceInterface,
    UnavailableProductsInterface,
} from "./UnavailableProductsApiServiceInterface";

@singleton()
export class GrowaveUnavailableProductsApiService
    implements UnavailableProductsApiServiceInterface
{
    constructor(
        @inject(commonAuthApiClientToken)
        private readonly apiClient: ApiClientInterface
    ) {}

    public getUnavailableProducts(
        body: UnavailableProductsInterface[]
    ): Observable<UnavailableProductEntity[]> {
        return this.apiClient
            .post({
                url: "/getUnavailableProducts",
                body: JSON.stringify({ products: body }),
                contentType: "application/json",
            })
            .pipe(
                map((response): UnavailableProductEntity[] => {
                    const responseData = create(
                        response.body,
                        array(UnavailableProductSchema)
                    );
                    return responseData;
                })
            );
    }
}
