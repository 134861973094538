export type ModalToken<T = unknown> = symbol & { __type?: T };

export class CallbackParams<T> {
    constructor(public modalName: ModalToken<T>) {}
}

export interface Modal<T> {
    defaultParams?: Partial<T>;
    params?: T;
}

export type ModalsMap = Map<ModalToken<unknown>, Modal<unknown>>;

export type OpenedModal<T> = {
    name: ModalToken<T>;
    params: T;
    order: number;
};

export type Event =
    | "afterRegister"
    | "afterOpen"
    | "beforeOpen"
    | "afterClose"
    | "beforeClose";

export type CallbackFunction = <T>(cb_params: CallbackParams<T>) => unknown;

export type Callbacks = {
    [key in Event]: CallbackFunction[];
};

export type Subscribers = Map<ModalToken<unknown>, (() => unknown)[]>;
