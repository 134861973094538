import { InferObjectType } from "@/types/InferObjectType";
import RewardsBrandingCupIcon from "@apps/rewards/components/Icons/RewardsBrandingIconsOptions/RewardsBrandingCupIcon.svelte";
import RewardsBrandingGiftIcon from "@apps/rewards/components/Icons/RewardsBrandingIconsOptions/RewardsBrandingGiftIcon.svelte";
import RewardsBrandingRibbonIcon from "@apps/rewards/components/Icons/RewardsBrandingIconsOptions/RewardsBrandingRibbonIcon.svelte";
import RewardsBrandingStarIcon from "@apps/rewards/components/Icons/RewardsBrandingIconsOptions/RewardsBrandingStarIcon.svelte";
import RewardsBrandingTicketIcon from "@apps/rewards/components/Icons/RewardsBrandingIconsOptions/RewardsBrandingTicketIcon.svelte";

import { REWARDS_BRANDING_LAUNCHER_ICON_TYPES } from "./rewardsBrandingTypes";

//Variables
export const RTL_DIRECTION = "rtl";
export const REMOVE_PROTOCOL_REGEX = /^https?:\/\//;
export const REFERRAL_SHARE_PATH = "referral";
export const REFERRAL_SHARE_CHANNELTYPE = "channelType";
export const POINTS_SHARE_PATH = "points";
export const EARNING_RULE_SHARE_RULE_ID = "ruleId";
export const EARNING_RULE_SHARE_EVENT_TYPE = "eventType";
export const SOCIAL_SHARE_CHANNELNAME = "channelName";
export const TOKEN = "token";
export const REWARDS_STOREFRONT_BASE_URL =
    __APP_ENV__ === "development"
        ? "https://app.dev.growave.io/storefront-api/rewards-storefront/v2"
        : "https://app.growave.io/storefront-api/rewards-storefront/v2";
export const SHARE_LINK = "{{ share_link }} ";
export const REFERRAL_CONTEXT_STORAGE_KEY = "gwReferralContext";
export const REFERRAL_CONTEXT_SEARCH_PARAM = "referralContext";
export const REWARDS_HOME_ANCHOR = "rewards-home";
export const PERCENTAGE_SYMBOL = "%";

//Enums
export enum KeyBoardEventKeys {
    ArrowDown = "ArrowDown",
    ArrowUp = "ArrowUp",
    Space = "Space",
    Escape = "Escape",
    Enter = "Enter",
}

export const REWARDS_WIDGETS_PLACEHOLDERS = {
    rewardsPopUpWidgetWrapperPlaceholder: ".gw-widget-placeholder",
    rewardsPopUpRootWidgetPlaceholder: ".gw-rd-popup-root-widget-placeholder",
    rewardsJoinNowModalWidgetPlaceholder: "body",
    rewardsVerifyAccountModalWidgetPlaceholder: "body",
    rewardsWaysToEarnPageWidgetPlaceholder:
        ".gw-rd-ways-to-earn-page-widget-placeholder",
    rewardsWaysToEarnPageWidgetModalPlaceholder: "body",
    rewardsWaysToSpendPageWidgetPlaceholder:
        ".gw-rd-ways-to-spend-page-widget-placeholder",
    rewardsWaysToSpendPageWidgetModalPlaceholder: "body",
    rewardsReferralProgramPageWidgetPlaceholder:
        ".gw-rd-referral-program-page-widget-placeholder",
    rewardsReferralsPageWidgetPlaceholder:
        ".gw-rd-referrals-page-widget-placeholder",
    rewardsHistoryPageWidgetPlaceholder:
        ".gw-rd-history-page-widget-placeholder",
    rewardsAvailableRewardsPageWidgetPlaceholder:
        ".gw-rd-available-rewards-page-widget-placeholder",
    rewardsAvailableRewardsPageWidgetModalPlaceholder: "body",
    rewardsVipTiersPageWidgetPlaceholder:
        ".gw-rd-vip-tiers-page-widget-placeholder",
    rewardsVipTiersCardWidgetPlaceholder:
        ".gw-rd-vip-tiers-card-widget-placeholder",
    rewardsVipTiersSingleProgressWidgetPlaceholder:
        ".gw-rd-vip-tiers-single-progress-widget-placeholder",
    rewardsVipTiersMultiProgressWidgetPlaceholder:
        ".gw-rd-vip-tiers-multi-progress-widget-placeholder",
    rewardsFaqPageWidgetPlaceholder: ".gw-rd-faq-page-widget-placeholder",
    rewardsEarnPointsProductPagePlaceholder:
        ".gw-rd-earn-points-product-page-widget-placeholder",
    rewardsLoyaltyInfoPageWidgetPlaceholder:
        ".gw-rd-loyalty-info-page-widget-placeholder",
    rewardsEmptyStatePageWidgetPlaceholder: ".gw-rd-page-wrapper",
    rewardsQrCodeModalPlaceholder: "body",
    rewardsCustomerPointsBalanceInlineWidgetPlaceholder:
        ".gw-rd-customer-points-balance-inline-widget-placeholder",
} as const;

export type RewardsWidgetsPlaceholdersType = InferObjectType<
    typeof REWARDS_WIDGETS_PLACEHOLDERS
>;

export enum RewardsTranslationNamespaces {
    commonCriticalPoints = "common.critical.Points",
    rewardsCriticalPopUpWidget = "rewards.critical.RewardsPopUpWidget",
    rewardsCriticalJoinNowModalWidget = "rewards.critical.RewardsJoinNowModalWidget",
    rewardsCriticalVerifyAccountModalWidget = "rewards.critical.RewardsVerifyAccountModalWidget",
    rewardsPopUpWidget = "rewards.RewardsPopUpWidget",
    rewardsWaysToEarnPageWidget = "rewards.RewardsWaysToEarnPageWidget",
    rewardsAvailableRewardsPageWidget = "rewards.RewardsAvailableRewardsPageWidget",
    rewardsWaysToSpendPageWidget = "rewards.RewardsWaysToSpendPageWidget",
    rewardsVipTiersPageWidget = "rewards.RewardsVipTiersPageWidget",
    rewardsVipTiersCardWidget = "rewards.RewardsVipTiersCardWidget",
    rewardsVipTiersSingleProgressWidget = "rewards.RewardsVipTiersSingleProgressWidget",
    rewardsVipTiersMultiProgressWidget = "rewards.RewardsVipTiersMultiProgressWidget",
    rewardsReferralProgramPageWidget = "rewards.RewardsReferralProgramPageWidget",
    rewardsReferralsPageWidget = "rewards.RewardsReferralsPageWidget",
    rewardsHistoryPageWidget = "rewards.RewardsHistoryPageWidget",
    rewardsFaqPageWidget = "rewards.RewardsFaqPageWidget",
    rewardsEmptyStatePageWidget = "rewards.RewardsEmptyStatePageWidget",
    rewardsLoyaltyInfoPageWidget = "rewards.RewardsLoyaltyInfoPageWidget",
    rewardsCriticalEarnPointsProductPageWidget = "rewards.critical.RewardsEarnPointsProductPageWidget",
}

//Do not delete or edit enum REWARDS_DATA_GW_SELECTORS
export enum REWARDS_DATA_GW_SELECTORS {
    earningRulePopup = "gw-earning-rule-popup",
    spendingRulePopup = "gw-spending-rule-popup",
    tierPopup = "gw-tier-popup",
    customerRewardsPopup = "gw-customer-rewards-popup",
    rewardsWaysToEarnPageWidget = "gw-ways-to-earn-page-widget",
    rewardsWaysToEarnPageWidgetModal = "gw-ways-to-earn-page-widget-modal",
    rewardsWaysToSpendPageWidget = "gw-ways-to-spend-page-widget",
    rewardsWaysToSpendPageWidgetModal = "gw-ways-to-spend-page-widget-modal",
    tierPage = "gw-tier-page",
    rewardsVipTiersCardWidget = "gw-vip-tiers-card-widget",
    rewardsVipTiersMultiProgressWidget = "gw-vip-tiers-multi-progress-widget",
    rewardsAvailableRewardsPageWidget = "gw-available-rewards-page-widget",
    rewardsAvailableRewardsPageWidgetModal = "gw-available-rewards-page-widget-modal",
    qrCodeRewardsModal = "gw-qr-code-rewards-modal",
}

export const REWARDS_BRANDING_ICONS = {
    [REWARDS_BRANDING_LAUNCHER_ICON_TYPES.default]: RewardsBrandingCupIcon,
    [REWARDS_BRANDING_LAUNCHER_ICON_TYPES.gift]: RewardsBrandingGiftIcon,
    [REWARDS_BRANDING_LAUNCHER_ICON_TYPES.ticket]: RewardsBrandingTicketIcon,
    [REWARDS_BRANDING_LAUNCHER_ICON_TYPES.star]: RewardsBrandingStarIcon,
    [REWARDS_BRANDING_LAUNCHER_ICON_TYPES.ribbon]: RewardsBrandingRibbonIcon,
};
