import { applyTransaction } from "@datorama/akita";
import { Observable, Subscription, bufferTime, filter, tap } from "rxjs";
import { distinct, map, mergeMap } from "rxjs/operators";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import {
    UnavailableProductsApiServiceInterface,
    UnavailableProductsInterface,
} from "../api_services/UnavailableProductsApiServiceInterface";
import { ProductStatuses } from "../constants/ProductStatuses";
import { UnavailableProductsStore } from "../stores/UnavailableProductsStore";
import { UnavailableProductsServiceInterface } from "./UnavailableProductsServiceInterface";

export class UnavailableProductsService
    implements UnavailableProductsServiceInterface
{
    private bufferedProductIds$: Observable<UnavailableProductsInterface[]>;
    private productsSubscription: Subscription | null = null;
    private isSubscribed = false;

    public constructor(
        private readonly unavailableProductsApiService: UnavailableProductsApiServiceInterface,
        private readonly unavailableProductsStore: UnavailableProductsStore,
        private readonly logger: LoggerInterface
    ) {
        this.bufferedProductIds$ = this.unavailableProductsStore
            ._select((state) => state.productStatuses)
            .pipe(
                mergeMap((productStatuses) => productStatuses),
                filter(({ status }) => status === ProductStatuses.IN_QUEUE),
                map((statusItem) => ({
                    id: statusItem.productId,
                    token: statusItem.token,
                })),
                distinct(),
                bufferTime(200)
            );
    }

    public addProductsForFetching(id: number, token: string) {
        this.logger.debug("UnavailableProductsService.addProductsForFetching", {
            id,
            token,
        });
        this.unavailableProductsStore.addProductInQueue(id, token);
    }

    private createProductsSubscription() {
        this.productsSubscription = this.bufferedProductIds$
            .pipe(
                filter((items) => items.length >= 1),
                tap((items) => {
                    applyTransaction(() => {
                        items.forEach(({ id }) => {
                            this.unavailableProductsStore.setLoadingStatus(id);
                        });
                    });
                }),
                mergeMap((items) => {
                    const requestBody: UnavailableProductsInterface[] =
                        items.map(({ id, token }) => ({ id, token }));

                    return this.unavailableProductsApiService.getUnavailableProducts(
                        requestBody
                    );
                }),
                tap((results) => {
                    applyTransaction(() => {
                        results.forEach((result) => {
                            this.unavailableProductsStore.add(result);
                            this.unavailableProductsStore.setReadyStatus(
                                result.id
                            );
                        });
                    });
                })
            )
            .subscribe();
    }

    public subscribe() {
        if (this.isSubscribed) return;

        this.logger.debug("UnavailableProductsService.subscribe");
        this.isSubscribed = true;

        this.createProductsSubscription();
    }

    public stop() {
        this.logger.debug("UnavailableProductsService.stop");
        this.productsSubscription?.unsubscribe();
    }
}
