// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MODAL */
:root,
:host {
    --gw-modal-padding: 0.7rem;
    --gw-modal-shadow: -1px 0px 20px var(--gw-shadow-from-ambient-light),
        0px 1px 5px var(--gw-shadow-from-direct-light);
    --gw-modal-borders-color: var(--gw-lightgrey-border-color);
    --gw-modal-dialog-bg: #fefefe;
    --gw-modal-backdrop-bg: #171717;
    --gw-modal-close-icon-color: #6f7276;
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/modal.css"],"names":[],"mappings":"AAAA,UAAU;AACV;;IAEI,0BAA0B;IAC1B;sDACkD;IAClD,0DAA0D;IAC1D,6BAA6B;IAC7B,+BAA+B;IAC/B,oCAAoC;AACxC","sourcesContent":["/* MODAL */\n:root,\n:host {\n    --gw-modal-padding: 0.7rem;\n    --gw-modal-shadow: -1px 0px 20px var(--gw-shadow-from-ambient-light),\n        0px 1px 5px var(--gw-shadow-from-direct-light);\n    --gw-modal-borders-color: var(--gw-lightgrey-border-color);\n    --gw-modal-dialog-bg: #fefefe;\n    --gw-modal-backdrop-bg: #171717;\n    --gw-modal-close-icon-color: #6f7276;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
