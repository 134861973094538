import {
    App,
    CART_PAGE_URL,
    COLLECTIONS_ALL_PAGE_URL,
    COLLECTIONS_PAGE_URL,
    LOGIN_PAGE_URL,
    PRODUCTS_PAGE_URL,
    REGISTER_PAGE_URL,
} from "@/constants";
import { GP_GW_REFFERER } from "@/constants/get_params";

import LocationControllerInterface from "../LocationController/LocationControllerInterface";
import { ShopifyUrlUtils } from "../ShopifyUrlUtils/ShopifyUrlUtils";

export class URLsBuilder {
    constructor(
        private readonly shopifyUrlUtils: ShopifyUrlUtils,
        private readonly locationController: LocationControllerInterface,
        private modifyURL = (url: string) => url
    ) {}

    public setModifyURL(modifyURL: (url: string) => string) {
        this.modifyURL = modifyURL;
    }

    private createParams(url: URL, params: Record<string, string | undefined>) {
        const searchParams = new URLSearchParams(url.search);

        for (const [key, value] of Object.entries(params)) {
            if (value) {
                searchParams.set(key, value);
            }
        }

        return searchParams;
    }

    private createUrl(
        path: string,
        params?: Record<string, string | undefined>,
        referrer?: string
    ) {
        const url = new URL(
            this.shopifyUrlUtils.withPrefix(path),
            this.locationController.getOrigin()
        );

        if (params) {
            const searchParams = this.createParams(url, params);
            url.search = searchParams.toString();
        }

        if (referrer) {
            const searchParams = this.createParams(url, {
                [GP_GW_REFFERER]: referrer,
            });
            url.search = searchParams.toString();
        }
        return this.modifyURL(url.toString());
    }

    public createProductUrl(
        productHandle: string,
        variantId?: number,
        referrer?: string
    ) {
        return this.createUrl(
            `${PRODUCTS_PAGE_URL}/${productHandle}`,
            {
                variant: variantId?.toString(),
            },
            referrer
        );
    }

    public createCollectionUrl(collectionHandle: string, referrer?: string) {
        return this.createUrl(
            `${COLLECTIONS_PAGE_URL}/${collectionHandle}`,
            undefined,
            referrer
        );
    }

    public createCollectionAllUrl(referrer?: string) {
        return this.createUrl(COLLECTIONS_ALL_PAGE_URL, undefined, referrer);
    }

    public createCartUrl(referrer?: string) {
        return this.createUrl(CART_PAGE_URL, undefined, referrer);
    }

    public createLoginUrl(referrer?: string) {
        return this.createUrl(LOGIN_PAGE_URL, undefined, referrer);
    }

    public createRegisterUrl(referrer?: string) {
        return this.createUrl(REGISTER_PAGE_URL, undefined, referrer);
    }

    public createWishlistPageUrl(referrer?: string) {
        return this.createUrl("/pages/wishlist", undefined, referrer);
    }

    public createGiftRegistryPageUrl(referrer?: string) {
        return this.createUrl(
            "/pages/wishlist",
            {
                tab: App.GiftRegistry,
            },
            referrer
        );
    }

    public createRewardsPageUrl(handle: string, referrer?: string) {
        return this.createUrl(`/pages/${handle}`, undefined, referrer);
    }

    public createMainPageUrl(referrer?: string) {
        return this.createUrl("/", undefined, referrer);
    }

    public createProductImageUrl(
        url: string,
        params?: Record<string, string | number | boolean>,
        defaultProtocol = "https"
    ) {
        if (url.length === 0) {
            return "";
        }

        if (!/^https?:\/\//i.test(url)) {
            if (url.startsWith("//")) {
                url = `${defaultProtocol}:${url}`;
            } else {
                url = `${defaultProtocol}://${url}`;
            }
        }

        const urlObj = new URL(url);

        params &&
            Object.entries(params).forEach(([key, value]) => {
                urlObj.searchParams.set(key, value.toString());
            });

        return urlObj.toString();
    }
}
