import { QueryEntity } from "@datorama/akita";
import { map } from "rxjs";
import { injectable } from "tsyringe";

import { ProductStatuses } from "../constants/ProductStatuses";
import {
    UnavailableProductsState,
    UnavailableProductsStore,
} from "../stores/UnavailableProductsStore";

@injectable()
export class UnavailableProductsQuery extends QueryEntity<UnavailableProductsState> {
    public constructor(
        private readonly unavailableProductsStore: UnavailableProductsStore
    ) {
        super(unavailableProductsStore);
    }

    public selectProductIds() {
        return this.select((state) => state.ids).pipe(map((ids) => ids ?? []));
    }

    public selectIsProductInLoadingStatus(productId: number) {
        return this.select((state) =>
            state.productStatuses.some(
                (statusItem) =>
                    statusItem.productId === productId &&
                    statusItem.status === ProductStatuses.LOADING
            )
        );
    }

    public selectProductStatus(productId: number) {
        return this.select(
            (state) =>
                state.productStatuses.find(
                    (statusItem) => statusItem.productId === productId
                )?.status
        );
    }

    public selectProducts() {
        return this.selectAll().pipe(map((products) => products));
    }
}
