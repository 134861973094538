import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { InjectionToken } from "tsyringe";

import { createModalToken } from "@components/Modal/ModalManager";
import { LoggerInterface } from "@interfaces/LoggerInterface";

import { REVIEW_TYPE } from "./constants";
import ReviewEntity from "./entities/ReviewEntity";
import ReviewsIconCollectionManager from "./utils/ReviewsIconCollectionManager";
import { LEAVE_REVIEW_SOURCE } from "./widgets/review_form_modal_widget/constants";

export const ReviewsApplicationConfigToken = Symbol(
    "ReviewsApplicationConfigToken"
);

export const reviewsApplicationLoggerToken = Symbol(
    "ReviewsApplicationLoggerToken"
) as InjectionToken<LoggerInterface>;

export const reviewsAuthApiClientToken = Symbol(
    "reviewsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const reviewsTranslationsAuthApiClientToken = Symbol(
    "reviewsTranslationsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const LeaveReviewPageApplicationConfigToken = Symbol(
    "LeaveReviewPageApplicationConfigToken"
);

export const questionsFormModalToken = createModalToken<{ productId: number }>(
    "questionsFormModalToken"
);

export const reviewsFormModalToken = createModalToken<{
    productId?: number;
    source: LEAVE_REVIEW_SOURCE;
    reviewType?: REVIEW_TYPE;
    reviewRequestToken?: string;
    orderId?: number;
    showPurchasedProducts?: boolean;
    onLeaveReviewCb?: (review: ReviewEntity) => void;
}>("reviewsFormModalToken");

export const reviewsGalleryModalToken = createModalToken(
    "reviewsGalleryModalToken"
);

export const reviewsIconCollectionManagerToken = Symbol(
    "reviewsIconCollectionManagerToken"
) as InjectionToken<ReviewsIconCollectionManager>;
