import { array, nullable, number, object, optional, string } from "superstruct";

const UnavailableProductImageSchema = object({
    id: number(),
    url: string(),
});

const UnavailableProductVariantsSchema = object({
    id: number(),
    title: string(),
});

export const UnavailableProductSchema = object({
    id: number(),
    title: string(),
    description: optional(nullable(string())),
    handle: optional(string()),
    price: optional(nullable(number())),
    imageUrl: optional(string()),
    images: optional(array(UnavailableProductImageSchema)),
    variants: optional(array(UnavailableProductVariantsSchema)),
});
