// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root,
:host {
    --gw-font-size-base: 16px;

    /* text */
    --gw-font-size-xlg: calc(var(--gw-font-size-base) * 1.5); /* 24px */
    --gw-font-size-xm: calc(var(--gw-font-size-base) * 1.375); /* 22px */
    --gw-font-size-xl: calc(var(--gw-font-size-base) * 1.25); /* 20px */
    --gw-font-size-lg: calc(var(--gw-font-size-base) * 1.125); /* 18px */
    --gw-font-size-md: calc(var(--gw-font-size-base) * 1); /* 16px */
    --gw-font-size-sm: calc(var(--gw-font-size-base) * 0.875); /* 14px */
    --gw-font-size-xs: calc(var(--gw-font-size-base) * 0.75); /* 12px */
    --gw-font-size-xxs: calc(var(--gw-font-size-base) * 0.625); /* 10px */

    /* text weight */
    --gw-font-weight-normal: 400;
    --gw-font-weight-medium: 500;
    --gw-font-weight-semibold: 600;
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/text.css"],"names":[],"mappings":"AAAA;;IAEI,yBAAyB;;IAEzB,SAAS;IACT,wDAAwD,EAAE,SAAS;IACnE,yDAAyD,EAAE,SAAS;IACpE,wDAAwD,EAAE,SAAS;IACnE,yDAAyD,EAAE,SAAS;IACpE,qDAAqD,EAAE,SAAS;IAChE,yDAAyD,EAAE,SAAS;IACpE,wDAAwD,EAAE,SAAS;IACnE,0DAA0D,EAAE,SAAS;;IAErE,gBAAgB;IAChB,4BAA4B;IAC5B,4BAA4B;IAC5B,8BAA8B;AAClC","sourcesContent":[":root,\n:host {\n    --gw-font-size-base: 16px;\n\n    /* text */\n    --gw-font-size-xlg: calc(var(--gw-font-size-base) * 1.5); /* 24px */\n    --gw-font-size-xm: calc(var(--gw-font-size-base) * 1.375); /* 22px */\n    --gw-font-size-xl: calc(var(--gw-font-size-base) * 1.25); /* 20px */\n    --gw-font-size-lg: calc(var(--gw-font-size-base) * 1.125); /* 18px */\n    --gw-font-size-md: calc(var(--gw-font-size-base) * 1); /* 16px */\n    --gw-font-size-sm: calc(var(--gw-font-size-base) * 0.875); /* 14px */\n    --gw-font-size-xs: calc(var(--gw-font-size-base) * 0.75); /* 12px */\n    --gw-font-size-xxs: calc(var(--gw-font-size-base) * 0.625); /* 10px */\n\n    /* text weight */\n    --gw-font-weight-normal: 400;\n    --gw-font-weight-medium: 500;\n    --gw-font-weight-semibold: 600;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
