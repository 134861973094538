import {
    BagIconsCollectionDefinition,
    BellIconsCollectionDefinition,
    CapsuleIconsCollectionDefinition,
    CoffeeIconsCollectionDefinition,
    CrownIconsCollectionDefinition,
    CupIconsCollectionDefinition,
    DefaultIconsCollectionDefinition,
    DiamondIconsCollectionDefinition,
    FireIconsCollectionDefinition,
    FlashIconsCollectionDefinition,
    GiftIconsCollectionDefinition,
    HeartIconsCollectionDefinition,
    LeafIconsCollectionDefinition,
    LoudspeakerIconsCollectionDefinition,
    MedalIconsCollectionDefinition,
    MusicIconsCollectionDefinition,
    PaintIconsCollectionDefinition,
    PetsIconsCollectionDefinition,
    PlantIconsCollectionDefinition,
    PlusIconsCollectionDefinition,
    StarBoldIconsCollectionDefinition,
    StarIconsCollectionDefinition,
    StarsIconsCollectionDefinition,
    TShirtIconsCollectionDefinition,
    TeddyIconsCollectionDefinition,
    TrophyIconsCollectionDefinition,
} from "../components/icons/customIcons/definitions";
import { REVIEWS_ICON_TYPES } from "./reviewIconTypes";

export const REVIEWS_ICON_DEFINITIONS = {
    [REVIEWS_ICON_TYPES.bag]: BagIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.bell]: BellIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.capsule]: CapsuleIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.coffee]: CoffeeIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.crown]: CrownIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.cup]: CupIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.diamond]: DiamondIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.fire]: FireIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.flash]: FlashIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.gift]: GiftIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.heart]: HeartIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.leaf]: LeafIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.loudspeaker]: LoudspeakerIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.medal]: MedalIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.music]: MusicIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.paint]: PaintIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.pets]: PetsIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.plant]: PlantIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.plus]: PlusIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.starBold]: StarBoldIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.default]: DefaultIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.star]: StarIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.stars]: StarsIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.teddy]: TeddyIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.trophy]: TrophyIconsCollectionDefinition,
    [REVIEWS_ICON_TYPES.tShirt]: TShirtIconsCollectionDefinition,
};
