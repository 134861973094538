import { create } from "superstruct";
import { singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import ShopBrandInfoEntity from "./ShopBrandInfoEntity";
import { ShopBrandInfoProviderInterface } from "./ShopBrandInfoProviderInterface";
import { ShopBrandInfoSchema } from "./ShopBrandInfoSchema";

@singleton()
class ShopBrandInfoFromStaticProvider
    implements ShopBrandInfoProviderInterface
{
    private shopBrandInfo: ShopBrandInfoEntity;

    constructor(
        private readonly logger: LoggerInterface,
        shopBrandInfo: ShopBrandInfoEntity
    ) {
        this.shopBrandInfo = create(shopBrandInfo, ShopBrandInfoSchema);
    }

    readData(): void {
        this.logger.debug("shop brand info provider is ready", {
            shopBrandInfo: this.shopBrandInfo,
        });
    }

    getLogoUrl(): string | null {
        return this.shopBrandInfo.logoUrl;
    }
}

export default ShopBrandInfoFromStaticProvider;
