// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root,
:host {
    --gw-drawer-btn-border-radius: 22px;
    --gw-drawer-btn-box-shadow: rgb(218, 218, 218) 0px 0px 2px;
    --gw-drawer-btn-text-color: var(--gw-dark-text-color);
    --gw-drawer-btn-bg-color: #fff;
    --gw-drawer-sidebar-bg: #fff;
    --gw-drawer-sidebar-width: 360px;
    --gw-drawer-sidebar-backdrop-bg: rgba(0, 0, 0, 0.4);
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/drawer.css"],"names":[],"mappings":"AAAA;;IAEI,mCAAmC;IACnC,0DAA0D;IAC1D,qDAAqD;IACrD,8BAA8B;IAC9B,4BAA4B;IAC5B,gCAAgC;IAChC,mDAAmD;AACvD","sourcesContent":[":root,\n:host {\n    --gw-drawer-btn-border-radius: 22px;\n    --gw-drawer-btn-box-shadow: rgb(218, 218, 218) 0px 0px 2px;\n    --gw-drawer-btn-text-color: var(--gw-dark-text-color);\n    --gw-drawer-btn-bg-color: #fff;\n    --gw-drawer-sidebar-bg: #fff;\n    --gw-drawer-sidebar-width: 360px;\n    --gw-drawer-sidebar-backdrop-bg: rgba(0, 0, 0, 0.4);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
