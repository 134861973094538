export * from "./key-codes";
export * from "./urls";
export const GW_ADDITIONAL_INFO_SELECTOR = "#gw-additional-info";
export const CURRENCY_PLACEHOLDER_REGEX = /\{\{\s*(\w+)\s*\}\}/;
export const PRODUCT_VARIANT_DEFAULT_TITLE = "Default Title";
export const WIDGET_SHADOW_ROOT_ENABLED_DATA_ATTR =
    "gw-widget-shadow-root-enabled";
export const DESIGN_MODE_CUSTOM_CSS_SCRIPT_SELECTOR =
    'script[type="text/custom-css"]';

export enum App {
    Login = "social-login",
    Rewards = "rewards",
    Reviews = "reviews",
    Wishlist = "wishlist",
    TikTok = "tiktok",
    Instagram = "instagram",
    Questions = "questions",
    IloomCustomization = "iloom-customization",
    GiftRegistry = "gift-registry",
}
