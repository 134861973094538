import { DynamicImportManager } from "@modules/dynamic_import/DynamicImportManager";
import { Observable, from, of, switchMap } from "rxjs";

import { REVIEWS_ICON_DEFINITIONS } from "../constants/reviewsIconDefinitions";
import ReviewsStorefrontValuesBrandingEntity from "../entities/ReviewsStorefrontValuesBrandingEntity";

class ReviewsIconCollectionManager {
    constructor(
        private readonly branding$: Observable<ReviewsStorefrontValuesBrandingEntity | null>,
        private readonly dynamicImportManager: DynamicImportManager
    ) {
        Object.values(REVIEWS_ICON_DEFINITIONS).forEach((iconDefinition) => {
            this.dynamicImportManager.register(iconDefinition);
        });
    }

    public getIconCollection() {
        return this.branding$.pipe(
            switchMap((branding) => {
                if (!branding) return of(null);
                const iconType = branding.reviewsIconSettings.iconType;
                const iconDefinition = REVIEWS_ICON_DEFINITIONS[iconType];
                return from(
                    this.dynamicImportManager.importDefault(
                        iconDefinition,
                        true
                    )
                );
            })
        );
    }
}

export default ReviewsIconCollectionManager;
