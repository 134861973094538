import { defineModule } from "@modules/dynamic_import/utils/defineModule";

export const BagIconsCollectionDefinition = defineModule({
    name: "BagIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "BagIconsCollection" */ "./BagIconsCollection"
        ),
});

export const BellIconsCollectionDefinition = defineModule({
    name: "BellIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "BellIconsCollection" */ "./BellIconsCollection"
        ),
});

export const CapsuleIconsCollectionDefinition = defineModule({
    name: "CapsuleIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "CapsuleIconsCollection" */ "./CapsuleIconsCollection"
        ),
});

export const CoffeeIconsCollectionDefinition = defineModule({
    name: "CoffeeIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "CoffeeIconsCollection" */ "./CoffeeIconsCollection"
        ),
});

export const CrownIconsCollectionDefinition = defineModule({
    name: "CrownIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "CrownIconsCollection" */ "./CrownIconsCollection"
        ),
});

export const CupIconsCollectionDefinition = defineModule({
    name: "CupIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "CupIconsCollection" */ "./CupIconsCollection"
        ),
});

export const DiamondIconsCollectionDefinition = defineModule({
    name: "DiamondIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "DiamondIconsCollection" */ "./DiamondIconsCollection"
        ),
});

export const FireIconsCollectionDefinition = defineModule({
    name: "FireIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "FireIconsCollection" */ "./FireIconsCollection"
        ),
});

export const FlashIconsCollectionDefinition = defineModule({
    name: "FlashIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "FlashIconsCollection" */ "./FlashIconsCollection"
        ),
});

export const GiftIconsCollectionDefinition = defineModule({
    name: "GiftIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "GiftIconsCollection" */ "./GiftIconsCollection"
        ),
});

export const HeartIconsCollectionDefinition = defineModule({
    name: "HeartIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "HeartIconsCollection" */ "./HeartIconsCollection"
        ),
});

export const LeafIconsCollectionDefinition = defineModule({
    name: "LeafIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "LeafIconsCollection" */ "./LeafIconsCollection"
        ),
});

export const LoudspeakerIconsCollectionDefinition = defineModule({
    name: "LoudspeakerIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "LoudspeakerIconsCollection" */ "./LoudspeakerIconsCollection"
        ),
});

export const MedalIconsCollectionDefinition = defineModule({
    name: "MedalIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "MedalIconsCollection" */ "./MedalIconsCollection"
        ),
});

export const MusicIconsCollectionDefinition = defineModule({
    name: "MusicIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "MusicIconsCollection" */ "./MusicIconsCollection"
        ),
});

export const PaintIconsCollectionDefinition = defineModule({
    name: "PaintIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "PaintIconsCollection" */ "./PaintIconsCollection"
        ),
});

export const PetsIconsCollectionDefinition = defineModule({
    name: "PetsIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "PetsIconsCollection" */ "./PetsIconsCollection"
        ),
});

export const PlantIconsCollectionDefinition = defineModule({
    name: "PlantIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "PlantIconsCollection" */ "./PlantIconsCollection"
        ),
});

export const PlusIconsCollectionDefinition = defineModule({
    name: "PlusIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "PlusIconsCollection" */ "./PlusIconsCollection"
        ),
});

export const StarBoldIconsCollectionDefinition = defineModule({
    name: "StarBoldIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "StarBoldIconsCollection" */ "./StarBoldIconsCollection"
        ),
});

export const DefaultIconsCollectionDefinition = defineModule({
    name: "DefaultIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "DefaultIconsCollection" */ "./DefaultIconsCollection"
        ),
});

export const StarIconsCollectionDefinition = defineModule({
    name: "StarIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "StarIconsCollection" */ "./StarIconsCollection"
        ),
});

export const StarsIconsCollectionDefinition = defineModule({
    name: "StarsIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "StarsIconsCollection" */ "./StarsIconsCollection"
        ),
});

export const TeddyIconsCollectionDefinition = defineModule({
    name: "TeddyIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "TeddyIconsCollection" */ "./TeddyIconsCollection"
        ),
});

export const TrophyIconsCollectionDefinition = defineModule({
    name: "TrophyIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "TrophyIconsCollection" */ "./TrophyIconsCollection"
        ),
});

export const TShirtIconsCollectionDefinition = defineModule({
    name: "TShirtIconsCollection",
    importCallback: () =>
        import(
            /* webpackChunkName: "TShirtIconsCollection" */ "./TShirtIconsCollection"
        ),
});
