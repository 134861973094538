import { AnalyticsEventsCollectorModule } from "@modules/analytics_events_collector/AnalyticsEventsCollectorModule";
import { BaseModule } from "@modules/base_module/BaseModule";
import { MODULE_STATUS } from "@modules/base_module/ModulesStatusesProvider/types";
import { singleton } from "tsyringe";

import SdkAnalyticsEventsFactory from "./analytics/SdkAnalyticsEventsFactory";
import CommonSdk from "./common_sdk/CommonSdk";
import { SDK_MODULES } from "./constants";
import SdkEvent from "./events/SdkEvent";
import { SdkEventInterface } from "./events/SdkEventInterface";
import { SdkRegisteredModuleInterface } from "./SdkRegisteredModuleInterface";
import { SdkInitOptions } from "./types";

@singleton()
class Sdk {
    private readonly _emitter: EventTarget;

    public readonly common?: CommonSdk;

    constructor(
        private readonly analyticsEventsCollectorModule: AnalyticsEventsCollectorModule,
        private readonly baseModule: BaseModule,
        private readonly analyticsEventsFactory: SdkAnalyticsEventsFactory,
        private readonly initApp?: (options: SdkInitOptions) => void
    ) {
        this._emitter = new EventTarget();
    }

    public init(options: SdkInitOptions) {
        if (this.initApp) {
            this.initApp(options);
        }
    }

    public on(type: string, callback: (event: Event) => void) {
        this._emitter.addEventListener(type, callback);
        this.analyticsEventsCollectorModule.exports.eventsCollector.pushEvent(
            this.analyticsEventsFactory.createEventSubscribed(type)
        );
    }

    public off(type: string, callback: (event: Event) => void) {
        this._emitter.removeEventListener(type, callback);
        this.analyticsEventsCollectorModule.exports.eventsCollector.pushEvent(
            this.analyticsEventsFactory.createEventUnsubscribed(type)
        );
    }

    public dispatch(event: SdkEventInterface) {
        this._emitter.dispatchEvent(new SdkEvent(event.type, event));
        // this.analyticsEventsCollectorModule.exports.eventsCollector.pushEvent(
        //     this.analyticsEventsFactory.createEventDispatched(event.type)
        // );
    }

    public registerModule(
        moduleName: SDK_MODULES,
        module: SdkRegisteredModuleInterface
    ) {
        module.register(this);

        this.baseModule.exports.modulesStatusesProvider.updateModuleStatus(
            moduleName,
            MODULE_STATUS.INITIALIZED
        );

        this.analyticsEventsCollectorModule.exports.eventsCollector.pushEvent(
            this.analyticsEventsFactory.createSdkModuleRegistered(moduleName)
        );
    }
}

export default Sdk;
