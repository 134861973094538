import type { Infer } from "superstruct";
import { literal, object } from "superstruct";
import type { InjectionToken } from "tsyringe";

import { REWARDS_WIDGETS_PLACEHOLDERS } from "./constants/constants";

export const RewardsApplicationConfigSchema = object({
    rewardsPopUpWidgetWrapperPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsPopUpWidgetWrapperPlaceholder
    ),
    rewardsPopUpRootWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsPopUpRootWidgetPlaceholder
    ),
    rewardsVerifyAccountModalWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsVerifyAccountModalWidgetPlaceholder
    ),
    rewardsJoinNowModalWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsJoinNowModalWidgetPlaceholder
    ),
    rewardsWaysToEarnPageWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsWaysToEarnPageWidgetPlaceholder
    ),
    rewardsWaysToEarnPageWidgetModalPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsWaysToEarnPageWidgetModalPlaceholder
    ),
    rewardsWaysToSpendPageWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsWaysToSpendPageWidgetPlaceholder
    ),
    rewardsWaysToSpendPageWidgetModalPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsWaysToSpendPageWidgetModalPlaceholder
    ),
    rewardsAvailableRewardsPageWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsAvailableRewardsPageWidgetPlaceholder
    ),
    rewardsAvailableRewardsPageWidgetModalPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsAvailableRewardsPageWidgetModalPlaceholder
    ),
    rewardsVipTiersPageWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsVipTiersPageWidgetPlaceholder
    ),
    rewardsVipTiersCardWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsVipTiersCardWidgetPlaceholder
    ),
    rewardsVipTiersSingleProgressWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsVipTiersSingleProgressWidgetPlaceholder
    ),
    rewardsVipTiersMultiProgressWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsVipTiersMultiProgressWidgetPlaceholder
    ),
    rewardsReferralProgramPageWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsReferralProgramPageWidgetPlaceholder
    ),
    rewardsReferralsPageWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsReferralsPageWidgetPlaceholder
    ),
    rewardsHistoryPageWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsHistoryPageWidgetPlaceholder
    ),
    rewardsEarnPointsProductPagePlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsEarnPointsProductPagePlaceholder
    ),
    rewardsLoyaltyInfoPageWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsLoyaltyInfoPageWidgetPlaceholder
    ),
    rewardsEmptyStatePageWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsEmptyStatePageWidgetPlaceholder
    ),
    rewardsQrCodeModalPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsQrCodeModalPlaceholder
    ),
    rewardsCustomerPointsBalanceInlineWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsCustomerPointsBalanceInlineWidgetPlaceholder
    ),
    rewardsFaqPageWidgetPlaceholder: literal(
        REWARDS_WIDGETS_PLACEHOLDERS.rewardsFaqPageWidgetPlaceholder
    ),
});

export type RewardsApplicationConfigInterface = Infer<
    typeof RewardsApplicationConfigSchema
>;

export const RewardsApplicationConfigToken = Symbol(
    "RewardsApplicationConfigToken"
) as InjectionToken<RewardsApplicationConfigInterface>;
