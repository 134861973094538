// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root,
:host {
    --gw-tabs-tab-btn: inherit;
    --gw-tabs-underline-color: var(--gw-theme__main-color);
    --gw-tabs-no-selected-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/tabs.css"],"names":[],"mappings":"AAAA;;IAEI,0BAA0B;IAC1B,sDAAsD;IACtD,wCAAwC;AAC5C","sourcesContent":[":root,\n:host {\n    --gw-tabs-tab-btn: inherit;\n    --gw-tabs-underline-color: var(--gw-theme__main-color);\n    --gw-tabs-no-selected-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
