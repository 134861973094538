import ApiClientRequest from "../ApiClientRequest";

class UnknownFetchException extends Error {
    constructor(
        public readonly request: ApiClientRequest,
        public readonly originalError: unknown
    ) {
        super(
            `Unknown fetch error, original error message: ${
                originalError instanceof Error
                    ? originalError.message
                    : String(originalError)
            }`
        );
    }
}

export default UnknownFetchException;
