import { GW_ADDITIONAL_INFO_SELECTOR } from "@/constants";
import { getJsonFromScript } from "@lib/utils";
import { create, type } from "superstruct";
import { inject, singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { loginApplicationLoggerToken } from "../../apps/login/tokens";
import ShopBrandInfoEntity from "./ShopBrandInfoEntity";
import { ShopBrandInfoProviderInterface } from "./ShopBrandInfoProviderInterface";
import { ShopBrandInfoSchema } from "./ShopBrandInfoSchema";

const AdditionalInfoSchema = type({
    GW_SHOP_BRAND_INFO: ShopBrandInfoSchema,
});

@singleton()
class ShopBrandInfoFromJsonProvider implements ShopBrandInfoProviderInterface {
    private shopBrandInfo: ShopBrandInfoEntity | null = null;

    constructor(
        @inject(loginApplicationLoggerToken)
        private readonly logger: LoggerInterface
    ) {}

    readData(): void {
        const additionalInfoData = getJsonFromScript(
            GW_ADDITIONAL_INFO_SELECTOR
        );
        const additionalInfo = create(additionalInfoData, AdditionalInfoSchema);
        this.shopBrandInfo = additionalInfo.GW_SHOP_BRAND_INFO;
        this.logger.debug("shop brand info provider is ready", {
            shopBrandInfo: this.shopBrandInfo,
        });
    }

    getLogoUrl(): string | null {
        return this.shopBrandInfo?.logoUrl || null;
    }
}

export default ShopBrandInfoFromJsonProvider;
