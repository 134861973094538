import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { InjectionToken } from "tsyringe";

import { ProductApiServiceInterface } from "./api_services/ProductApiServiceInterface";
import { ProductsApiServiceInterface } from "./api_services/ProductsApiServiceInterface";
import { ProductsLiquidApiServiceInterface } from "./api_services/ProductsLiquidApiServiceInterface";
import { UnavailableProductsApiServiceInterface } from "./api_services/UnavailableProductsApiServiceInterface";
import { ProductsQuery } from "./queries/ProductsQuery";
import { ProductsWithQuantityRulesQuery } from "./queries/ProductsWithQuantityRulesQuery";
import { UnavailableProductsQuery } from "./queries/UnavailableProductsQuery";
import { ProductsServiceInterface } from "./services/ProductsServiceInterface";
import { UnavailableProductsServiceInterface } from "./services/UnavailableProductsServiceInterface";
import { ProductsStore } from "./stores/ProductsStore";
import { ProductsWithQuantityRulesStore } from "./stores/ProductsWithQuantityRulesStore";
import { UnavailableProductsStore } from "./stores/UnavailableProductsStore";

export const productApiServiceToken = Symbol(
    "productsApiService"
) as InjectionToken<ProductApiServiceInterface>;

export const unavailableProductsApiServiceToken = Symbol(
    "unavailableProductsApiServiceToken"
) as InjectionToken<UnavailableProductsApiServiceInterface>;

export const productsApiServiceToken = Symbol(
    "productsApiServiceToken"
) as InjectionToken<ProductsApiServiceInterface>;

export const productLiquidApiServiceToken = Symbol(
    "productLiquidApiServiceToken"
) as InjectionToken<ProductsLiquidApiServiceInterface>;

export const productsStoreToken = Symbol(
    "productsStoreToken"
) as InjectionToken<ProductsStore>;

export const unavailableProductsStoreToken = Symbol(
    "unavailableProductsStoreToken"
) as InjectionToken<UnavailableProductsStore>;

export const productsWithQuantityRulesStoreToken = Symbol(
    "productsWithQuantityRulesStoreToken"
) as InjectionToken<ProductsWithQuantityRulesStore>;

export const productsServiceToken = Symbol(
    "productsServiceToken"
) as InjectionToken<ProductsServiceInterface>;

export const unavailableProductsServiceToken = Symbol(
    "unavailableProductsServiceToken"
) as InjectionToken<UnavailableProductsServiceInterface>;

export const productsQueryToken = Symbol(
    "productsQueryToken"
) as InjectionToken<ProductsQuery>;

export const unavailableProductsQueryToken = Symbol(
    "unavailableProductsQueryToken"
) as InjectionToken<UnavailableProductsQuery>;

export const productsWithQuantityRulesQueryToken = Symbol(
    "productsWithQuantityRulesQueryToken"
) as InjectionToken<ProductsWithQuantityRulesQuery>;

export const commonAuthApiClientToken = Symbol(
    "commonAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;
