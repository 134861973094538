// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* VIDEO-PLAYER (prefix: 'vp') */
:root,
:host {
    --gw-vp-main-color: #e86c8b;
    /* button */
    --gw-vp-button-radius: 0.1rem;
    --gw-vp-button-padding: 0.4rem;
    --gw-vp-button-default-scale: 1;
    /* slider colors */
    --gw-vp-slider-track-bg: transparent;
    --gw-vp-slider-track-color: white;
    --gw-vp-slider-progress-color: rgba(192, 192, 192, 0.4);
    --gw-vp-slider-buffered-color: rgba(192, 192, 192, 0.5);
    --gw-vp-slider-thumb-color: white;
    /* slider sizes */
    --gw-vp-slider-track-height: 3px;
    --gw-vp-slider-thumb-diameter: 8px;
    /* current time */
    --gw-vp-current-time-color: white;
    --gw-vp-current-time-font-size: 1.1rem;
    --gw-vp-current-time-font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/video-player.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;;IAEI,2BAA2B;IAC3B,WAAW;IACX,6BAA6B;IAC7B,8BAA8B;IAC9B,+BAA+B;IAC/B,kBAAkB;IAClB,oCAAoC;IACpC,iCAAiC;IACjC,uDAAuD;IACvD,uDAAuD;IACvD,iCAAiC;IACjC,iBAAiB;IACjB,gCAAgC;IAChC,kCAAkC;IAClC,iBAAiB;IACjB,iCAAiC;IACjC,sCAAsC;IACtC,qCAAqC;AACzC","sourcesContent":["/* VIDEO-PLAYER (prefix: 'vp') */\n:root,\n:host {\n    --gw-vp-main-color: #e86c8b;\n    /* button */\n    --gw-vp-button-radius: 0.1rem;\n    --gw-vp-button-padding: 0.4rem;\n    --gw-vp-button-default-scale: 1;\n    /* slider colors */\n    --gw-vp-slider-track-bg: transparent;\n    --gw-vp-slider-track-color: white;\n    --gw-vp-slider-progress-color: rgba(192, 192, 192, 0.4);\n    --gw-vp-slider-buffered-color: rgba(192, 192, 192, 0.5);\n    --gw-vp-slider-thumb-color: white;\n    /* slider sizes */\n    --gw-vp-slider-track-height: 3px;\n    --gw-vp-slider-thumb-diameter: 8px;\n    /* current time */\n    --gw-vp-current-time-color: white;\n    --gw-vp-current-time-font-size: 1.1rem;\n    --gw-vp-current-time-font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
