import { CURRENT_APP_QUERY_PARAMETER } from "@/constants/current_app";
import { globalLoggerToken, gwStorefrontAppInfoToken } from "@/tokens";
import ApiClient from "@lib/ApiClient";
import AuthApiClient from "@lib/ApiClient/AuthApiClient";
import { CommonModule } from "@modules/common_module/CommonModule";
import { TokenManagerModule } from "@modules/token_manager/TokenManagerModule";
import { DependencyContainer, container, singleton } from "tsyringe";

import { ModuleInterface } from "@interfaces/ModuleInterface";

import AnalyticsEventsCollectorInterface from "./AnalyticsEventsCollector/AnalyticsEventsCollectorInterface";
import { AnalyticsEventsFactory } from "./AnalyticsEventsCollector/AnalyticsEventsFactory";
import RxJSAnalyticsEventsCollector from "./AnalyticsEventsCollector/RxJSAnalyticsEventsCollector";
import AnalyticsEventsSender from "./AnalyticsEventsSender/AnalyticsEventsSender";
import { eventsCollectorToken } from "./di_tokens";
import MetricsCollectorApiService from "./MetricsCollectorApiService";

type AnalyticsEventsCollectorModuleExports = {
    eventsFactory: AnalyticsEventsFactory;
    eventsCollector: AnalyticsEventsCollectorInterface;
    startSending: () => void;
};

@singleton()
export class AnalyticsEventsCollectorModule
    implements ModuleInterface<AnalyticsEventsCollectorModuleExports>
{
    exports: AnalyticsEventsCollectorModuleExports;
    public constructor() {
        const eventsCollectorModuleLogger = container
            .resolve(globalLoggerToken)
            .newLogger("EventsCollectorModule");

        const eventsFactory = new AnalyticsEventsFactory(
            window.performance,
            navigator.userAgent,
            location.href,
            __APP_NAME__
        );
        const eventsCollector = new RxJSAnalyticsEventsCollector();
        container.registerInstance(eventsCollectorToken, eventsCollector);
        container.registerInstance(AnalyticsEventsFactory, eventsFactory);

        // Call this method to start sending events to the server, call it only once and after growaveConfiguration is defined
        const startSending = () => {
            const commonModule = container.resolve(CommonModule);
            const tokenManagerModule = container.resolve(TokenManagerModule);
            const apiClient = new AuthApiClient(
                new ApiClient(
                    commonModule.exports.baseUrlProvider.appendToBaseUrl(
                        "/events-collector-lambda/"
                    ),
                    undefined,
                    {
                        [CURRENT_APP_QUERY_PARAMETER]: container.resolve(
                            gwStorefrontAppInfoToken
                        ).currentApp,
                        ...commonModule.exports.designModeQueryParamsProvider
                            .queryParams,
                    },
                    eventsCollectorModuleLogger
                ),
                tokenManagerModule.exports.tokenManager
            );
            const eventsSender = new AnalyticsEventsSender(
                eventsCollector,
                new MetricsCollectorApiService(
                    apiClient,
                    eventsCollectorModuleLogger
                )
            );

            eventsSender.start();
        };

        this.exports = {
            eventsFactory,
            eventsCollector,
            startSending,
        };
    }

    registerProviders(di: DependencyContainer) {
        return {};
    }
}
