import { Infer, object, string } from "superstruct";
import { InjectionToken } from "tsyringe";

export const IloomCustomizationApplicationConfigSchema = object({
    designFeedPageWidgetPlaceholderSelector: string(),
    designFeedHomeWidgetPlaceholderSelector: string(),
    designFeedProductWidgetPlaceholderSelector: string(),
    wishlistPageWidgetPlaceholderSelector: string(),
    reviewsPageWidgetPlaceholderSelector: string(),
});

export type IloomCustomizationApplicationConfigInterface = Infer<
    typeof IloomCustomizationApplicationConfigSchema
>;

export const IloomCustomizationApplicationConfigToken = Symbol(
    "IloomCustomizationApplicationConfigToken"
) as InjectionToken<IloomCustomizationApplicationConfigInterface>;
