function filterLanguageIsoCode(isoCode: string) {
    const validExceptions = ["PT_BR", "PT_PT", "ZH_CN", "ZH_TW"];

    // https://shopify.dev/docs/api/storefront/2024-07/enums/LanguageCode
    const filteredIsoCode = isoCode.replace("-", "_");

    if (validExceptions.includes(filteredIsoCode)) {
        return filteredIsoCode;
    }

    if (filteredIsoCode.includes("_")) {
        return filteredIsoCode.split("_")[0];
    }

    return filteredIsoCode;
}

export default filterLanguageIsoCode;
