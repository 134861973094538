// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root,
:host {
    --gw-rv-light-grey-color: hsl(0, 0%, 95%);

    --gw-rv-fit-color-1: #ff5d15;
    --gw-rv-fit-color-2: #fec601;
    --gw-rv-fit-color-3: #2da866;
    --gw-rv-fit-color-4: #fec601;
    --gw-rv-fit-color-5: #ff5d15;
    --gw-rv-fit-color-default: #333;
    --gw-rv-fit-color-empty: #e1e1e1;

    --gw-rv-review-reply-bg: #fafafa;
    --gw-rv-progress-bar-bg: #ededed;
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/reviews.css"],"names":[],"mappings":"AAAA;;IAEI,yCAAyC;;IAEzC,4BAA4B;IAC5B,4BAA4B;IAC5B,4BAA4B;IAC5B,4BAA4B;IAC5B,4BAA4B;IAC5B,+BAA+B;IAC/B,gCAAgC;;IAEhC,gCAAgC;IAChC,gCAAgC;AACpC","sourcesContent":[":root,\n:host {\n    --gw-rv-light-grey-color: hsl(0, 0%, 95%);\n\n    --gw-rv-fit-color-1: #ff5d15;\n    --gw-rv-fit-color-2: #fec601;\n    --gw-rv-fit-color-3: #2da866;\n    --gw-rv-fit-color-4: #fec601;\n    --gw-rv-fit-color-5: #ff5d15;\n    --gw-rv-fit-color-default: #333;\n    --gw-rv-fit-color-empty: #e1e1e1;\n\n    --gw-rv-review-reply-bg: #fafafa;\n    --gw-rv-progress-bar-bg: #ededed;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
