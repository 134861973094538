export const KEY_CODE_ESC = 27;
export const KEY_CODE_ARROW_LEFT = 37;
export const KEY_CODE_ARROW_RIGHT = 39;
export const KEY_CODE_SPACE = 32;
export const KEY_CODE_BACKSPACE = 8;

export const KEY_ESC = "Escape";
export const KEY_ARROW_LEFT = "ArrowLeft";
export const KEY_ARROW_RIGHT = "ArrowRight";
export const KEY_SPACE = " ";
export const KEY_ENTER = "Enter";
export const KEY_ARROW_DOWN = "ArrowDown";
export const KEY_ARROW_UP = "ArrowUp";
export const KEY_BACKSPACE = "Backspace";
export const KEY_TAB = "Tab";
export const KEY_SHIFT = "Shift";
