import { App } from "@/constants";
import {
    array,
    boolean,
    enums,
    nullable,
    optional,
    string,
    type,
} from "superstruct";

const GrowaveConfigurationBodySchema = type({
    moneyFormat: string(),
    moneyWithCurrencyFormat: string(),
    shopCurrency: string(),
    currencySymbol: string(),
    availableApps: array(enums(Object.values(App))),
    storefrontApiAccessToken: nullable(string()),
    countryIsoCode: string(),
    languageIsoCode: string(),
    currencyIsoCode: string(),
    gwI18nLocale: string(),
    shopName: string(),
    isShadowRootDisabledGlobally: boolean(),
    myshopifyDomain: string(),
    initedFromSdk: optional(boolean()),
    isDiscountApplyAvailable: boolean(),
    isCartAvailable: boolean(),
});

export default GrowaveConfigurationBodySchema;
