import { Infer, object, string } from "superstruct";
import { InjectionToken } from "tsyringe";

export const GiftRegistryApplicationConfigSchema = object({
    addToRegistryProductPageWidgetPlaceholder: string(),
    sharedRegistryPageWidgetPlaceholder: string(),
    addToRegistryModalWidgetPlaceholder: string(),
    registryFormModalWidgetPlaceholder: string(),
    giftCartDrawerWidgetPlaceholder: string(),
});

export type GiftRegistryApplicationConfigInterface = Infer<
    typeof GiftRegistryApplicationConfigSchema
>;

export const GiftRegistryApplicationConfigToken = Symbol(
    "GiftRegistryApplicationConfigToken"
) as InjectionToken<GiftRegistryApplicationConfigInterface>;
