// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Nudges (prefix: 'ng') */

:root {
    /* spaces */
    --gw-ng-space-5: var(--gw-space-5); /* 10px */
    --gw-rd-space-8: var(--gw-space-8); /* 16px */

    /* main colors */
    --gw-ng-main-color: 0 0% 10%;
    --gw-ng-primary-color: hsl(var(--gw-ng-main-color));

    /* borders */
    --gw-ng-border-radius-base: 10px;

    /* shadows */
    --gw-ng-main-shadow: rgba(0, 0, 0, 0.05);
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/nudges.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B;IACI,WAAW;IACX,kCAAkC,EAAE,SAAS;IAC7C,kCAAkC,EAAE,SAAS;;IAE7C,gBAAgB;IAChB,4BAA4B;IAC5B,mDAAmD;;IAEnD,YAAY;IACZ,gCAAgC;;IAEhC,YAAY;IACZ,wCAAwC;AAC5C","sourcesContent":["/* Nudges (prefix: 'ng') */\n\n:root {\n    /* spaces */\n    --gw-ng-space-5: var(--gw-space-5); /* 10px */\n    --gw-rd-space-8: var(--gw-space-8); /* 16px */\n\n    /* main colors */\n    --gw-ng-main-color: 0 0% 10%;\n    --gw-ng-primary-color: hsl(var(--gw-ng-main-color));\n\n    /* borders */\n    --gw-ng-border-radius-base: 10px;\n\n    /* shadows */\n    --gw-ng-main-shadow: rgba(0, 0, 0, 0.05);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
